.footer {
  background-color: #222;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  left: 0; /* Add this line to ensure the footer starts from the left */
  width: 100vw; /* Use viewport width to guarantee full-width */
  z-index: 1000;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%; /* Ensure the container spans the full width */
}

.footer p {
  margin: 0;
  color: #aaa;
}

.footer p:first-child {
  font-weight: bold;
  color: #fff;
}

.footer p a {
  color: #ffa500;
  text-decoration: none;
}

.footer p a:hover {
  text-decoration: underline;
}
