.App {
    text-align: center;
    padding: 20px;
}

.navbar {
    background-color: #f8f9fa;
    padding-left: 0; /* Remove left padding for edge alignment on mobile */
    padding-right: 0; /* Remove right padding for edge alignment on mobile */
}

.form-inline input {
    max-width: 200px;
}

.navbar-nav {
    flex-grow: 1;
}

.navbar-collapse {
    justify-content: space-between;
}

.search-section {
    margin-top: 50px;
}

.logo {
    width: 150px;
    height: auto;
}

.search-bar {
    width: 500px; /* For larger screens */
    max-width: 90%; /* Prevents overflowing on smaller screens */
    border-radius: 50px;
    margin: 20px auto;
    padding: 10px;
}

.button-group .btn {
    background-color: #dff0ff;
    margin: 5px;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
    .form-inline {
        display: none;
    }
    .search-section {
        margin-top: 20px;
    }

    .logo {
        width: 120px; /* Slightly reduce the logo size on smaller screens */
    }

    .search-bar {
        width: 60%; /* Full width for mobile */
        max-width: 250px; /* Set a reasonable maximum width */
        padding: 10px;
        font-size: 14px; /* Adjust font size for better visibility on small screens */
    }

    .button-group {
        display: flex;
        flex-direction: row; /* Stack buttons vertically */
        justify-content: space-between;
    }

    .button-group .btn {
        width: 100%; /* Full width for buttons */
        margin: 5px 0;
        font-size: 14px; /* Adjust button text size */
        padding: 8px; /* Reduce padding slightly */
    }

    .results-section .card {
        margin: 5px;
        padding: 10px;
    }

    .navbar-collapse {
        justify-content: flex-start;
        padding-left: 0; /* Align the navbar items to the edge */
    }

    .navbar-collapse.show {
        display: flex;
        flex-direction: column;
    }

    .navbar-nav {
        flex-direction: column;
        align-items: flex-start; /* Align the nav items to the left */
    }

    .navbar-nav .nav-item {
        margin: 0px 0px;
    }
}
